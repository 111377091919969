// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-clandestine-ops-js": () => import("./../src/pages/projects/clandestine-ops.js" /* webpackChunkName: "component---src-pages-projects-clandestine-ops-js" */),
  "component---src-pages-projects-comic-manager-js": () => import("./../src/pages/projects/comic-manager.js" /* webpackChunkName: "component---src-pages-projects-comic-manager-js" */),
  "component---src-pages-projects-ibd-classes-js": () => import("./../src/pages/projects/ibd-classes.js" /* webpackChunkName: "component---src-pages-projects-ibd-classes-js" */),
  "component---src-pages-projects-phalanx-js": () => import("./../src/pages/projects/phalanx.js" /* webpackChunkName: "component---src-pages-projects-phalanx-js" */),
  "component---src-pages-projects-planet-library-js": () => import("./../src/pages/projects/planet-library.js" /* webpackChunkName: "component---src-pages-projects-planet-library-js" */),
  "component---src-pages-projects-what-to-watch-js": () => import("./../src/pages/projects/what-to-watch.js" /* webpackChunkName: "component---src-pages-projects-what-to-watch-js" */)
}

